import * as React from "react"
import { IconButton } from "@smartdevis/ui/src/Button"
import { FlexRow, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { Domain } from "@smartdevis/server/src/domain"
import { calculateOrder, sortSortables } from "@smartdevis/utils/src/comparable"
import { SMap, values } from "@smartdevis/utils/src/map"
import { F0, F1 } from "@smartdevis/utils/src/types"
import { isEmpty, validString } from "@smartdevis/utils/src/validators"
import { MutationCreators } from "@smartdevis/client/src/utils/mutations"
import { mkFormSchema, mkTextareaSchema } from "../../../components/forms/formSchemas"
import { mkCell } from "../../../components/table/TableCell"
import { EditableRow } from "../../../components/table/TableRow"
import { useItemsMap } from "../../../hooks/utilityHooks"
import { i18n } from "@smartdevis/client/src/services/translations"
import { useUnsavedStatusAsChild } from "../../UnsavedStatusProvider"
import { DragDropContext, Draggable, DropResult,Droppable } from "react-beautiful-dnd"
import styled from "styled-components"

type GeneralInformationListProps = {
    isReadonly: boolean
    items: SMap<Domain.GeneralInformation>

    mkItem: (order: number) => Domain.GeneralInformation
} & MutationCreators<Domain.GeneralInformation>

export const GeneralInformationList = (p: GeneralInformationListProps) => {
    const {
        items: newItems,
        setItemByKey: setNewItem,
        removeItem: removeNewItem
    } = useItemsMap<Domain.GeneralInformation>({}, "informationId")

    const allItems = { ...p.items, ...newItems }

    const sortedItems = sortSortables(values(allItems))
    const sortedKeys = sortedItems.map(v => v.informationId)

    const { setUnsaved } = useUnsavedStatusAsChild("GeneralInformationList")
    React.useEffect(() => {
        setUnsaved(!isEmpty(newItems))
    }, [isEmpty(newItems)])

    const rows = sortedKeys.map((k, index) => (
                    <StaticRow
                        key={k}
                        index={index}
                        readonly={p.isReadonly}
                        rowId={k}
                        value={allItems[k].value}
                        onSubmit={value => {
                            if (newItems[k]) {
                                p.create({ ...newItems[k], value })
                                removeNewItem(k)
                            } else p.update(allItems[k])({ value })
                        }}
                        onDelete={() => (newItems[k] ? removeNewItem(k) : p.remove(allItems[k])())}
                    />
                ))

    return (
        <>
            <GeneralInfoRow<"GeneralInformation">  
                        rows={rows} 
                         draggable={!p.isReadonly} 
                         onDrag={(_, index, ids) => {
                                                        const id = ids.draggedId
                                                        const sortedItems = sortSortables(values(allItems))
                                                        const order = calculateOrder(sortedItems, sortedItems.indexOf(allItems[id]),index)
                                                        return p.update(allItems[id])({ order })
                                                    }
                                }
            />
            <VerticalSpace base="8px" />
            {!p.isReadonly && (
                <FlexRow>
                    <IconButton icon="CrossWhite" onClick={() => setNewItem(p.mkItem(values(allItems).length))}>
                        {i18n("Text paragraph")}
                    </IconButton>
                </FlexRow>
            )}
        </>
    )
}

const mkSchema = () => mkFormSchema<{ value: string }>({ value: validString }, { value: mkTextareaSchema("Text") })

const StaticRow: React.FC<{
    rowId: string
    value: string
    index: number
    onSubmit: F1<string>
    onDelete: F0
    readonly: boolean
}> = p => (
    <DraggableContainer rowId={p.rowId} index={p.index}>
        <EditableRow
            isEdited={!p.value}
            formSchema={mkSchema()}
            rowId={p.rowId}
            cells={[
                    // (mkDragHandleCell(``)) as any, WIP
                    mkCell<{ value: string }>(p.value, ["noPadding"], { editMode: "formless", field: "value" })
                  ]}
            readonly={p.readonly}
            visuals={["noBorder", "bottomMargin"]}
            formValue={{ value: p.value }}
            actionOnEnter="submit"
            actionOnBlur="submit"
            onSubmit={v => p.onSubmit(v.value)}
            onDelete={p.onDelete}
        />
    </DraggableContainer>
)

type GeneralRowProps<T extends string> = {
    onDrag?: (type: T, index: number, ids: { parentId: string | null; draggedId: string }) => void
    rows: React.JSX.Element[]
    draggable?: boolean
}

const GeneralInfoRow = <T extends string>(p: GeneralRowProps<T>): React.ReactElement => {
    const dragCallback = (res: DropResult) => {
        if (!res.destination) return
        const {
            draggableId: draggedId,
            destination: { droppableId: parentId }
        } = res
        p.onDrag?.(res.type as T, res.destination.index, {
            parentId: parentId === "table" ? null : parentId,
            draggedId
        })
    }

    return <DragDropContainer onDrag={dragCallback} rows={p.rows} draggable={p.draggable} />
}

const DragDropContainer: React.FC<{
    onDrag: (res: DropResult) => void
    rows: React.JSX.Element[]
    draggable?: boolean
}> = p =>
    p.draggable ? (
        <DragDropContext onDragEnd={p.onDrag}>
            <DroppableContainer id={"droppable"}>{p.rows}</DroppableContainer>
        </DragDropContext>
    ) : (
        <>{p.rows}</>
    )

const DraggableContainer: React.FC<{ index: number; rowId: string; draggable?: boolean }> = p => {
    return (
        <Draggable key={p.rowId} draggableId={p.rowId} index={p.index}>
            {provided => (
                <DragHelperZone ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    {p.children}
                </DragHelperZone>
            )}
        </Draggable>
    )
}

const DroppableContainer: React.FC<{ id: string; type: string; draggable?: boolean }> = p => {
    return (
        <Droppable droppableId={p.id}>
            {provided => (
                <DragHelperZone ref={provided.innerRef} {...provided.droppableProps}>
                    {p.children}
                    {provided.placeholder}
                </DragHelperZone>
            )}
        </Droppable>
    )
}

const DragHelperZone = styled.div`
    min-height: 1px;
`