import * as React from "react"
import { i18n } from "@smartdevis/client/src/services/translations"
import { FlexColumn, FlexRow, HorizontalSpace, Margin, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { IconButton } from "@smartdevis/ui/src/Button"
import { Row, Table } from "../../../components/table/Table"
import { getRowVisualFromDelta, MutationManagement } from "@smartdevis/client/src/utils/devisHelpers"
import { SMap, values } from "@smartdevis/utils/src/map"
import { _noop, identity, isDefined } from "@smartdevis/utils/src/misc"
import { isEmpty } from "@smartdevis/utils/src/validators"
import { sortSortables, calculateOrder } from "@smartdevis/utils/src/comparable"
import { conditionValidation } from "@smartdevis/server/src/models/position"
import { F0, F1 } from "@smartdevis/utils/src/types"
import { AttachmentsManagement, AttachmentsModal, mkAttachmentCell } from "../../../components/Attachments"
import { mkFormSchema, mkStyledCustom, mkTextareaSchema } from "../../../components/forms/formSchemas"
import { mkCell, mkDragHandleCell, mkAssetPlaceholderCell, Cell } from "../../../components/table/TableCell"
import { getDevisReadonlyRowExplanationText } from "../DevisSection"
import { Domain } from "@smartdevis/server/src/domain"
import { Spinner } from "@smartdevis/ui/src/Spinner"
import { isStringNumber } from "@smartdevis/utils/src/numbers"
import { CWorkTitle, Version, mkVersion } from "@smartdevis/server/src/domainCatalog"
import { useNullableState } from "../../../hooks/utilityHooks"
import { getFeatureFlag } from "../../../utils/flagHelpers"
import { getProcessEnv } from "../../../utils/envHelpers"
import { CatalogFormAttributeType, CatalogPicker } from "../devis-catalogs/devis-catalogs"
import { DisplayAttributes } from "../../../components/DisplayAttributes"
import { StyledFormSchema } from "@smartdevis/forms"
import { mkCatalogRef } from "../../../utils/catalogs"
import { P } from "@smartdevis/ui/src/Typography"
import { IdLite, genTemporaryId } from "@smartdevis/utils/src/id"
const getDummyCatalogVersion = mkVersion(12)
const showCatalogs = getFeatureFlag(getProcessEnv(), "feature_show_catalog_positions") === "show"

type ConditionsListProps = {
    isReadonly: boolean
    promptOnEmptyText?: string
    shelfConditionsVersion?: Version | null
    mkItem: (order: number) => Domain.Condition
    mutations: MutationManagement<"conditions", Domain.Condition>
    attachments: AttachmentsManagement
    deltas?: SMap<Domain.RoundDelta>
    conditions: SMap<Domain.Condition>
    resetCatalogs: F0
}

export const ConditionsList = (p: ConditionsListProps) => {
    const [attachmentModalConditionId, setAttachmentConditionId] = React.useState<string | undefined>(undefined)
    const [catalogCondition, setCatalogCondition] = useNullableState<Domain.Condition>()
    const [,] = React.useState()

    const onModalClose = () => {
        setCatalogCondition(null)
        p.resetCatalogs()
    }

    const sortedItems = sortSortables(values(p.mutations.allItems))
    const rows = sortedItems.map((c, i) =>
        mkConditionRow(
            c,
            i,
            {
                readonly: p.isReadonly,
                onDelete: p.mutations.onItemRemove(c),
                onSubmit: p.mutations.onItemSubmit(c),
                isEdited: p.mutations.isUnsaved(c.conditionId),
                onRevert: p.mutations.onRevertDelta?.(p.deltas?.[c.conditionId]?.deltaId)
            },
            mkAttachmentCell(
                p.attachments.getItemsForRef(c.conditionId).length,
                () => setAttachmentConditionId(c.conditionId),
                p.isReadonly
            ),
            p.deltas?.[c.conditionId]
        )
    )

    return (
        <>
            {isEmpty(p.mutations.allItems) ? null : (
                <Table<"condition" | "header">
                    rowHierarchy={["condition"]}
                    onDrag={(_, index, ids) => {
                        const id = ids.draggedId
                        const order = calculateOrder(sortedItems, sortedItems.indexOf(p.mutations.allItems[id]), index)
                        if (p.mutations.isUnsaved(id))
                            return p.mutations.setUnsavedItem({ ...p.mutations.allItems[id], order })
                        return p.mutations.onItemSubmit(p.mutations.allItems[id])({ order })
                    }}
                    draggable={!p.isReadonly}
                    rows={[mkHeaderRow(p.isReadonly), ...rows]}
                />
            )}
            {!p.isReadonly && (
                <Margin values="16px 0">
                    <FlexRow>
                        <IconButton
                            icon="CrossWhite"
                            onClick={() => p.mutations.setUnsavedItem(p.mkItem(values(p.mutations.allItems).length))}>
                            {i18n("New free text condition")}
                        </IconButton>
                        <HorizontalSpace base="16px" />
                        {isDefined(showCatalogs && p.shelfConditionsVersion) && (
                            <IconButton
                                icon="CrossWhite"
                                onClick={() => setCatalogCondition(p.mkItem(values(p.mutations.allItems).length))}>
                                {i18n("New catalog condition")}
                            </IconButton>
                        )}
                    </FlexRow>
                </Margin>
            )}
            {isDefined(p.shelfConditionsVersion?p.shelfConditionsVersion:true) && isDefined(catalogCondition) && (
                <CatalogPicker
                    shelfType="conditions"
                    visible
                    onClose={onModalClose}
                    shelfVersion={p.shelfConditionsVersion?p.shelfConditionsVersion:getDummyCatalogVersion}
                    item={catalogCondition}
                    itemsToPreview={p.conditions}
                    previewTitle={i18n("Conditions preview")}
                    mkNewItem={() => p.mkItem(values(p.mutations.allItems).length)}
                    mkCopyItem={cond => ({
                        ...cond,
                        conditionId: genTemporaryId(),
                        order: values(p.mutations.allItems).length
                    })}
                    attachCatalog={attachCatalogToCondition}
                    mkSchema={mkConditionSchema}
                    mkInitial={identity}
                    getItemId={i => i.conditionId}
                    renderItem={renderCatalogCondition}
                    renderHeader={renderCatalogConditionHeader}
                    mkStyledSchema={mkConditionStyledSchema}
                    onSubmit={s1 => p.mutations.onItemSubmit(s1)({})}
                    onRemove={position => p.mutations.onItemRemove(position)()}
                />
            )}
            <AttachmentsModal
                visible={!!attachmentModalConditionId}
                isReadonly={p.isReadonly}
                refId={attachmentModalConditionId}
                onClose={() => setAttachmentConditionId(undefined)}
                {...p.attachments}
            />
        </>
    )
}

type ConditionPayload = Pick<Domain.Condition, "name" | "description">

const mkConditionRow = (
    cond: Domain.Condition,
    index: number,
    rowOptions: {
        readonly: boolean
        onDelete: F0
        onSubmit: F1<ConditionPayload>
        isEdited?: boolean
        onClick?: F0
        onRevert?: F0
    },
    attachmentCell: Cell<ConditionPayload>,
    delta?: Domain.RoundDelta
): Row<"condition", ConditionPayload> => {
    return {
        mode: "editable",
        type: "condition",
        formSchema: mkConditionSchema(),
        formValue: cond,
        rowId: cond.conditionId,
        readonlyClickMessage: cond.catalogRef ? undefined : getDevisReadonlyRowExplanationText(),
        actionOnBlur: "submit",
        grid: [2, 8, 12, 2],
        visuals: getRowVisualFromDelta(delta).concat(
            cond.catalogRef && !rowOptions.readonly ? ["clickable", "noBorder"] : "noBorder"
        ),cells: [
            rowOptions.readonly ? mkCell(`0.${index + 1}`) : mkDragHandleCell(`0.${index + 1}`),
            mkCell(cond.name, [], { editMode: "formless", field: "name" }),
            //mkCell(cond.description, [], { editMode: "formless", field: "description" }),
            cond.catalogRef
            ? mkCell(<DisplayAttributes attributes={cond.catalogRef.attributes} order={cond.catalogRef.order} />)
            : mkCell(cond.description, [], { editMode: "formless", field: "description" }),
            rowOptions.isEdited ? mkCell("") : isStringNumber(cond.conditionId) ? attachmentCell : mkCell(<Spinner />) //its for the fake ids (genTemporaryId)
        ],
        ...rowOptions
    }
}

const mkHeaderRow = (readonly: boolean): Row<"header", any> => ({
    mode: "static",
    type: "header",
    visuals: ["header"],
    cells: [
        readonly ? mkCell(i18n("ID")) : mkAssetPlaceholderCell(i18n("ID")),
        mkCell(i18n("Name")),
        mkCell(i18n("Description")),
        mkCell(i18n("Attachments"))
    ],
    grid: [2, 8, 12, 2],
    noDrag: true,
    rowId: "header"
})

const mkConditionSchema = () =>
    mkFormSchema<ConditionPayload>(conditionValidation, {
        name: mkTextareaSchema(i18n("Name")),
        description: mkTextareaSchema(i18n("description"))
    })

    const mkConditionStyledSchema = (): StyledFormSchema<ConditionPayload, CatalogFormAttributeType> => [
        { type: "Title", value: i18n("Details") },
        "name",
        { type: "Title", value: i18n("Attributes") },
        mkStyledCustom("preset" as const), // This tells formless to use custom renderer (customRenderer func.)
        { type: "Title", value: i18n("Custom Attributes") },
        mkStyledCustom("custom" as const) // This tells formless to use custom renderer (customRenderer func.)
    ]
    
    const attachCatalogToCondition = (condition: Domain.Condition, catalogId: IdLite, workTitle: CWorkTitle): Domain.Condition => ({
        ...condition,
        name: workTitle.title,
        catalogRef: mkCatalogRef(catalogId, workTitle),
        description: ""
    })
    
    const renderCatalogCondition = (condition: Domain.Condition) => (
        <FlexColumn>
            <P small>
                <b>{condition.name || ""}</b>
            </P>
            <VerticalSpace base="8px" />
            {condition.catalogRef ? (
                <DisplayAttributes {...condition.catalogRef} />
            ) : (
                <P small>{condition.description || "-"}</P>
            )}
        </FlexColumn>
    )
    
    const renderCatalogConditionHeader = () => (
        <P small color="grey70">
            {i18n("Name")}
        </P>
    )
    