import { LicenseUnlockInfo, SubscribedWorkStations } from "@smartdevis/server/src/crbNpkCatalogs"
import { Authentication } from "../authState"
import { Cmd } from "redux-loop"
import { clientActions } from ".."
import { Async, mkFetchError, mkFetched, mkFetching } from "@smartdevis/utils/src/async"
import { mkFetchCmd } from "../dataQueries"
import { requestData } from "../../services/server"
import { NPKCatalogs } from "@smartdevis/server/src/domain"

export type LicenseUnlockRequestPayload = {
    customerID: string,
    licenseID: string,
    workStationID: string,
    userId: string
}

export const CrbServerLogon = (auth: Authentication, body: LicenseUnlockRequestPayload) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setCRBAccessAuth(mkFetching())),
            core: Cmd.run(
                () =>
                    requestData<NPKCatalogs.AccessStatus>("authorized", {
                        path: "/catalogs/Login",
                        auth,
                        validators: [],
                        isV2: true,
                        reqMethod: "POST",
                        body
                    })
                ,
                {
                    successActionCreator: res => clientActions._setCRBAccessAuth(res),
                    failActionCreator: (error: Error) =>
                        clientActions._setCRBAccessAuth(mkFetchError(error.message))
                }
            )
        },
    )


export const fetchNPKChapters = (auth: Authentication, body: LicenseUnlockRequestPayload) =>
        mkFetchCmd(
            {
                pre: Cmd.action(clientActions._setNPKChapters(mkFetching())),
                core: Cmd.run(
                    () =>
                        requestData<NPKCatalogs.Chapters>("authorized", {
                            path: "/catalogs/GetChapters",
                            auth,
                            validators: [],
                            isV2: true,
                            reqMethod: "POST",
                            body
                        })
                    ,
                    {
                        successActionCreator: res => clientActions._setNPKChapters(res),
                        failActionCreator: (error: Error) =>
                            clientActions._setNPKChapters(mkFetchError(error.message))
                    }
                )
            },
        )

export const fetchNPKLanguages = (auth: Authentication) => 
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setNPKLanguages(mkFetching())),
            core: Cmd.run(
                () =>
                    requestData<NPKCatalogs.Languages>("authorized", {
                        path: "/catalogs/GetLanguages",
                        auth,
                        validators: [],
                        isV2: true,
                        reqMethod: "GET"
                    })
                ,
                {
                    successActionCreator: res => clientActions._setNPKLanguages(res),
                    failActionCreator: (error: Error) =>
                        clientActions._setNPKLanguages(mkFetchError(error.message))
                }
            )
        },
    )

export const fetchNPKChapterVersions = (auth:Authentication, body: NPKCatalogs.NPKChapterVersionRequest) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setNPKChapterVersions(body.chapter,mkFetching())),
            core: Cmd.run(
                () =>
                    requestData<NPKCatalogs.NPKChapterVersions>("authorized", {
                        path: "/catalogs/GetChapterVersion",
                        auth,
                        validators: [],
                        isV2: true,
                        reqMethod: "POST",
                        body
                    })
                ,
                {
                    successActionCreator: res => clientActions._setNPKChapterVersions(body.chapter,res),
                    failActionCreator: (error: Error) =>
                        clientActions._setNPKChapterVersions(body.chapter,mkFetchError(error.message))
                }
            )
        },
    )

export const fetchNPKChapterDetails = (auth: Authentication, body: NPKCatalogs.NPKChapterVersionRequest & {year: string}) =>  
    mkFetchCmd(
    {
        pre: Cmd.action(clientActions._setNPKChapterDetails(mkFetching())),
        core: Cmd.run(
            () =>
                requestData<NPKCatalogs.NPKChapter>("authorized", {
                    path: "/catalogs/GetChapterDetails",
                    auth,
                    validators: [],
                    isV2: true,
                    reqMethod: "POST",
                    body
                })
            ,
            {
                successActionCreator: res => clientActions._setNPKChapterDetails(res),
                failActionCreator: (error: Error) =>
                    clientActions._setNPKChapterDetails(mkFetchError(error.message))
            }
        )
    },
)

// export const fetchSubscribedWorkstations = (auth: Authentication, body: Pick<LicenseUnlockRequestPayload, "customerID" | "licenseID" | "abbreviation">) =>
//     mkFetchCmd(
//         {
//             pre: Cmd.action(clientActions._setApp("subscribedWorkStation", mkFetching())),
//             core: Cmd.run(
//                 () => requestData<SubscribedWorkStations>("authorized", {
//                     path: "/crb/License/unSubscribeWorkStation",
//                     auth,
//                     validators: [],
//                     isV2: true,
//                     reqMethod: "POST",
//                     body
//                 })
//                 ,
//                 {
//                     successActionCreator: res => clientActions._setApp("subscribedWorkStation", res),
//                     failActionCreator: (error: Error) =>
//                         clientActions._setCatalogsRes("SubscribedWorkStations", mkFetchError(error.message))
//                 }
//             )
//         },
//     )