import { Accordion, AccordionPanel2, useAccordion } from "@smartdevis/ui/src/Accordion"
import { themeColor } from "@smartdevis/ui/src/utils/theme";
import { values } from "lodash";
import React from "react";
import styled from "styled-components";
import { NPKCatalogs } from "@smartdevis/server/src/domain";

const NPKAccordionContainer = styled.div`
    border: 1px solid ${themeColor("grey60")};
    border-radius: 8px;
    > div > div {
        &:first-of-type > div {
            border-top: none;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        &:nth-last-of-type(-n + 2) > div {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
`

export type NpkCatalogsProps = {
    position?: NPKCatalogs.ArrayOfNPKNode
    level: number
}

export const NpkCatalogs = (p: NpkCatalogsProps) => {
    const [open, onToggle] = useAccordion([], { multiple: true })

    const getTitle = (number: string, title: string, level?: number): string => {

        switch (level) {
            case 3: return `.${number.substring(3, number.length)} ${title}`;
            case 4: return `.${number.substring(3, number.length)} ${title}`;
            case 5: return `.${number.substring(3, number.length)} ${title}`;
            case 6: return `${number} ${title}`;
            default: return `${number.substring(0, 3)} ${title}`;
        }
    }


    return (
        <div style={{width:"100%"}}>
            {/* <VerticalSpace base="16px" /> */}
            <NPKAccordionContainer>
                <Accordion selected={open} onChange={onToggle} animationMs={0}>
                    {values(p.position?.npknode).map(t => (
                        <AccordionPanel2
                            key={t.number}
                            header={getTitle(t.number,t.title,p.level)}
                            description={t.description}
                            iconPosition="left"
                            openedIcon="ArrowDown"
                            closedIcon="ArrowRight"
                            styles={{
                                padding: "15px"
                            }}>
                            {NpkCatalogs({position:t.subNodes,level:p.level+1})}
                        </AccordionPanel2>
                    ))}
                </Accordion>
            </NPKAccordionContainer>
        </div>
    )
}

