import * as React from "react"
import AnimateHeight from "react-animate-height"
import { F1 } from "@smartdevis/utils/src/types"
import { Asset, ImageAsset } from "../Asset"
import { assertIsPanel } from "./utils"
import { Container, PanelWrapper, PanelContainer, PanelIcon, PanelHeader } from "./styles"
import styled from "styled-components"
import { rem } from "../utils"
import { themeColor } from "../utils/theme"

export { useAccordion } from "./hooks"

export type AccordionProps = {
    selected: string[]
    onChange: F1<string>
    animationMs?: number
}

export const Accordion: React.FC<AccordionProps> = ({ selected, onChange, animationMs = 200, children }) => (
    <Container>
        {React.Children.map(children, child => {
            assertIsPanel(child)
            const { children: panelChildren, ...rest } = child.props
            const active = selected.includes(child.key as string)

            return (
                <>
                    <PanelWrapper onClick={() => onChange(child.key as string)}>
                        <child.type {...rest} active={active} />
                    </PanelWrapper>
                    <AnimateHeight height={active ? "auto" : 0} duration={animationMs}>
                        {panelChildren}
                    </AnimateHeight>
                </>
            )
        })}
    </Container>
)

export type IconPosition = "left" | "right"

export type AccordionPanelProps = {
    key: string
    header: string | React.ReactNode
    iconPosition?: IconPosition
    openedIcon?: ImageAsset
    closedIcon?: ImageAsset
    active?: boolean
    styles?: React.CSSProperties
    description? : string
}

export const AccordionPanel: React.FC<AccordionPanelProps> = ({
    header,
    iconPosition = "left",
    openedIcon = "Minus",
    closedIcon = "Plus",
    active,
    styles,
}) => {
    return (
        <PanelContainer
            style={{
                flexDirection: iconPosition === "left" ? "row" : "row-reverse",
                ...styles
            }}>
            <PanelIcon iconPosition={iconPosition}>
                <Asset size="icon" name={active ? openedIcon : closedIcon} color="grey70" />
            </PanelIcon>
            <PanelHeader>{header}</PanelHeader>
        </PanelContainer>
    )
}

export const AccordionPanel2: React.FC<AccordionPanelProps> = ({
    header,
    iconPosition = "left",
    openedIcon = "Minus",
    closedIcon = "Plus",
    active,
    styles,
    description
}) => {
    return (
        <PanelContainer
            style={{
                flexDirection: iconPosition === "left" ? "row" : "row-reverse",
                ...styles
            }}>
            <PanelIcon iconPosition={iconPosition}>
                <Asset size="icon" name={active ? openedIcon : closedIcon} color="grey70" />
            </PanelIcon>
            <div style={{flexDirection:"column"}}>
                <PanelHeader2>{header}</PanelHeader2>
                <PanelBody>{description}</PanelBody>
            </div>
        </PanelContainer>
    )
}


const PanelHeader2 = styled.div`
    flex: 1;
    min-width: 0;
    font-size: ${rem(22)};
    font-weight: bold;
    line-height: 1.25;
    word-wrap: break-word;
    color: ${themeColor("primaryGrey")};
`


const PanelBody = styled.div`
    flex: 1;
    min-width: 0;
    font-size: ${rem(22)};
    line-height: 1.25;
    word-wrap: break-word;
    color: ${themeColor("primaryGrey")};
`