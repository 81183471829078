import * as React from "react"
import { IconButton } from "@smartdevis/ui/src/Button"
import { i18n } from "@smartdevis/client/src/services/translations"
import { FlexRow, HorizontalSpace, VerticalSpace, Padding, Margin } from "@smartdevis/ui/src/utils/common"
import { IncompletePosition, attachCatalogToPosition, mkCatalogPositionStyledSchema, mkIncompletePosition, mkPositionRowBuilder, mkPositionSchema, mkPostionHeaderRow, renderCatalogPosition, renderCatalogPositionHeader } from "../devis-positions/DevisPosition.helpers"
import { Table } from "../../../components/table/Table"
import { getSublevelIndex, mkDevisPositionsRows, MutationManagement } from "@smartdevis/client/src/utils/devisHelpers"
import { calculateOrder } from "@smartdevis/utils/src/comparable"
import { SMap, omitObject, toMap } from "@smartdevis/utils/src/map"
import { F1 } from "@smartdevis/utils/src/types"
import { asyncConnect } from "../../../resolvers"
import { mkSectionRow } from "../DevisSection"
import { AttachmentsManagement, AttachmentsModal, mkAttachmentCell } from "../../../components/Attachments"
import { last } from "@smartdevis/utils/src/array"
import { Domain } from "@smartdevis/server/src/domain"
import { identity, isDefined } from "@smartdevis/utils/src/misc"
import { getFeatureFlag } from "../../../utils/flagHelpers"
import { getProcessEnv } from "../../../utils/envHelpers"
import { useNullableState } from "../../../hooks/utilityHooks"
import { CatalogPicker } from "../devis-catalogs/devis-catalogs"
import { genTemporaryId } from "@smartdevis/utils/src/id"
import { isOk } from "@smartdevis/utils/src/result"
import { runValidatorsRaw } from "@smartdevis/utils/src/validators"
import { validatePosition } from "@smartdevis/server/src/models/position"
import { mkVersion } from "@smartdevis/server/src/domainCatalog"
import { NpkCatalogsView } from "../npk-catalogs/npk-catalogs"
import { LicenseUnlockRequestPayload } from "../../../store/crbNpkCatalogsState"
import { NpkCatalogsLogin } from "../npk-catalogs/npk-catalogs-login"
const accessCrbCatalogs = true;

const getDummyCatalogVersion = mkVersion(12)
const showCatalogs = getFeatureFlag(getProcessEnv(), "feature_show_catalog_positions") === "show"
export const PositionsList: React.FC<{
    readonly: boolean
    predefinedReadonly: boolean
    onAddPosition: (sectionId: string, delta?: Partial<Domain.Position>) => () => void
    onAddSubsection: (delta?: Partial<Domain.Section>) => () => void
    onAddSection: (delta?: Partial<Domain.Section>) => () => void
    sortedSections: Domain.Section[]
    sortedSublevels: SMap<Domain.Sublevel[]>
    devis: Pick<Domain.Devis, "devisId" | "shelfVersion" | "chapterVersion">
    sm: MutationManagement<"sections", Domain.Section>
    subsm: MutationManagement<"sections", Domain.Section>
    pm: MutationManagement<"positions", Domain.Position>
    pushNotification: F1<string, void>
    deltas: SMap<Domain.RoundDelta> | undefined
    attachments: AttachmentsManagement
}> = asyncConnect({ 
    stateResolvers: ["auth","user","NPKLanguages","NPKChapters","NPKChapterVersions","NPKChapterDetails","CRBAccessAuth","CRBAccessAuth","results"], 
    actions: ["resetCatalogMatches","logon","_setCRBAccessAuth","fetchNPKChapters","fetchNPKLanguages","fetchNPKChapterDetails","fetchNPKChapterVersions"] })(p => {
    const [catalogPosition, setCatalogPosition] = useNullableState<IncompletePosition>()
    const [crbCatalogPosition, setCrbCatalogsPosition] = useNullableState<IncompletePosition>();
    const [licenseDetails, setLicenseDetails] = React.useState<LicenseUnlockRequestPayload>({} as LicenseUnlockRequestPayload);
    const [attachmentModalPositionId, setAttachmentPositionId] = React.useState<string | undefined>(undefined)

    const onModalClose = React.useCallback(() => {
        setCrbCatalogsPosition(null)
        setCatalogPosition(null)
        p.resetCatalogMatches()
    }, [p.resetCatalogMatches, setCatalogPosition])

    const mkPositionRow = React.useCallback(
        mkPositionRowBuilder(
            (pos: Domain.Position) =>
                mkAttachmentCell(
                    p.attachments.getItemsForRef(pos.positionId).length,
                    () => setAttachmentPositionId(pos.positionId),
                    false
                ),
            { readonly: p.readonly, predefinedReadonly: p?.predefinedReadonly, isUnsaved: p.pm.isUnsaved },
            {
                setCatalogPosition,
                onPositionRemove: p.pm.onItemRemove,
                onPositionSubmit: p.pm.onItemSubmit,
                onDeltaRevert: p.pm.onRevertDelta
            }
        ),
        [p.pm.allItems, p.sm.allItems, p.readonly, p.predefinedReadonly]
    )

    const rows = mkDevisPositionsRows(
        p.sortedSections,
        p.sortedSublevels,
        (type, s, children) =>
            mkSectionRow(
                omitObject(s, ["basedOn"]),
                {
                    type,
                    readonly: p.readonly,
                    predefinedReadonly: p?.predefinedReadonly,
                    onSubmit: (s.parentId ? p.subsm : p.sm).onItemSubmit(s),
                    onDelete: (s.parentId ? p.subsm : p.sm).onItemRemove(s),
                    onRevert: (s.parentId ? p.subsm : p.sm).onRevertDelta?.(p.deltas?.[s.sectionId]?.deltaId),
                    children
                },
                { withNumber: true, withDragIcon: true },
                p.deltas?.[s.sectionId]
            ),
        pos => mkPositionRow(omitObject(pos, ["basedOn"]), p.deltas?.[pos.positionId])
    )

    return (
        <>
            <Table<"section" | "sublevel" | "lastlevel" | "header">
                rows={[mkPostionHeaderRow(p.readonly), ...rows]}
                rowHierarchy={["section", "sublevel", "lastlevel"]}
                draggable={!p.readonly}
                renderAfterRowMap={{
                    section: ({ rowId, hovered }) => {
                        if (!hovered || p.readonly || p.sm.isUnsaved(rowId)) return <VerticalSpace base="40px" />
                        const rowSubsections =
                            p.sortedSublevels[rowId]?.filter(
                                (s): s is Domain.SectionSublevel => s.basedOn === "section"
                            ) ?? []

                        const lastSubsectionNumber = rowSubsections.reduce(
                            (acc, s) => (parseInt(s.number ?? "0", 10) > acc ? parseInt(s.number ?? "0", 10) : acc),
                            0
                        )

                        const lastNumber = Math.max(rowSubsections.length, lastSubsectionNumber)
                        const lastOrder = Math.max(rowSubsections.length, (last(rowSubsections)?.order ?? 0) + 1)
                        return (
                            <Padding values="4px 16px 12px">
                                <FlexRow alignCenter>
                                    <IconButton
                                        onClick={p.onAddSubsection({
                                            name: i18n("Subsection $1", lastNumber + 1),
                                            number: `${lastNumber + 1}`,
                                            parentId: rowId,
                                            order: lastOrder
                                        })}
                                        icon="CrossWhite"
                                        btnType="secondary">
                                        {i18n("New subsection")}
                                    </IconButton>
                                    <HorizontalSpace base="16px" />
                                    <IconButton onClick={p.onAddPosition(rowId)} icon="CrossWhite">
                                        {i18n("New free text position")}
                                    </IconButton>
                                    <HorizontalSpace base="16px" />
                                    <IconButton
                                        onClick={p.onAddPosition(rowId, { type: "per", amount: 0 })}
                                        icon="CrossWhite">
                                        {i18n("New PER position")}
                                    </IconButton>
                                    <HorizontalSpace base="16px" />
                                    {isDefined(showCatalogs && p.devis.shelfVersion ? p.devis.shelfVersion : true) && (
                                        <IconButton
                                            onClick={() =>
                                                setCatalogPosition(
                                                    mkIncompletePosition(
                                                        rowId,
                                                        p.devis.devisId,
                                                        p.sortedSublevels[rowId]?.length || 0
                                                    )
                                                )
                                            }
                                            icon="CrossWhite">
                                            {i18n("New catalog position")}
                                        </IconButton>
                                    )}
                                    <HorizontalSpace base="16px" />
                                    {(accessCrbCatalogs) && (
                                        <IconButton onClick={() =>
                                            // setCatalogPosition(
                                            //     mkIncompletePosition(
                                            //         rowId,
                                            //         p.devis.devisId,
                                            //         p.sortedSublevels[rowId]?.length || 0
                                            //     )
                                            // )

                                            setCrbCatalogsPosition(
                                                mkIncompletePosition(
                                                    rowId,
                                                    p.devis.devisId,
                                                    p.sortedSublevels[rowId]?.length || 0
                                                ))
                                        }
                                            icon="CrossWhite">
                                            {i18n("Access CRB catalogs")}
                                        </IconButton>
                                    )

                                    }

                                </FlexRow>
                            </Padding>
                        )
                    },
                    sublevel: ({ rowId, hovered }) => {
                        if (p.pm.allItems[rowId]) return // that means its not a section
                        if (!hovered || p.readonly || p.subsm.isUnsaved(rowId)) return <VerticalSpace base="32px" />
                        return (
                            <Padding values="4px 16px 4px">
                                <FlexRow alignCenter>
                                    <HorizontalSpace base="32px" />
                                    <IconButton onClick={p.onAddPosition(rowId)} icon="CrossWhite">
                                        {i18n("New free text position")}
                                    </IconButton>
                                    <HorizontalSpace base="16px" />
                                    <IconButton
                                        onClick={p.onAddPosition(rowId, { type: "per", amount: 0 })}
                                        icon="CrossWhite">
                                        {i18n("New PER position")}
                                    </IconButton>
                                    <HorizontalSpace base="16px" />
                                    {isDefined(showCatalogs && p.devis.shelfVersion ? p.devis.shelfVersion : true) && (
                                        <IconButton
                                            onClick={() =>
                                                setCatalogPosition(
                                                    mkIncompletePosition(
                                                        rowId,
                                                        p.devis.devisId,
                                                        p.sortedSublevels[rowId]?.length || 0
                                                    )
                                                )
                                            }
                                            icon="CrossWhite">
                                            {i18n("New catalog position")}
                                        </IconButton>
                                    )}
                                    <HorizontalSpace base="16px" />
                                    {(accessCrbCatalogs) && (
                                        <IconButton onClick={() =>
                                            // setCatalogPosition(
                                            //     mkIncompletePosition(
                                            //         rowId,
                                            //         p.devis.devisId,
                                            //         p.sortedSublevels[rowId]?.length || 0
                                            //     )
                                            // )
                                            setCrbCatalogsPosition(
                                                mkIncompletePosition(
                                                    rowId,
                                                    p.devis.devisId,
                                                    p.sortedSublevels[rowId]?.length || 0
                                                ))
                                        }
                                            icon="CrossWhite">
                                            {i18n("Access CRB catalogs")}
                                        </IconButton>
                                    )

                                    }

                                </FlexRow>
                            </Padding>
                        )
                    }
                }}
                onDrag={(type, index, ids) => {
                    switch (type) {
                        case "lastlevel":
                        case "sublevel": {
                            if (!ids.parentId) return
                            if (p.pm.allItems[ids.parentId])
                                return p.pushNotification(i18n("This position can only be moved to a subsection"))
                            const sls = p.sortedSublevels[ids.parentId]

                            const order = calculateOrder(sls, getSublevelIndex(sls, ids.draggedId), index)
                            if (p.subsm.allItems[ids.draggedId])
                                p.subsm.onItemSubmit(p.subsm.allItems[ids.draggedId])({
                                    order,
                                    parentId: ids.parentId
                                })
                            else p.pm.onItemSubmit(p.pm.allItems[ids.draggedId])({ sectionId: ids.parentId, order })
                            break
                        }
                        case "section": {
                            const order = calculateOrder(
                                p.sortedSections,
                                p.sortedSections.indexOf(p.sm.allItems[ids.draggedId]),
                                index
                            )
                            p.sm.onItemSubmit(p.sm.allItems[ids.draggedId])({ order })
                        }
                    }
                }}
            />
            <Margin values="46px 0">
                {p.readonly ? null : (
                    <IconButton
                        icon="CrossWhite"
                        data-cy="positions-add-section"
                        btnType="secondary"
                        onClick={() => {
                            const lastSubsectionNumber = p.sortedSections.reduce(
                                (acc, s) => (parseInt(s.number ?? "0", 10) > acc ? parseInt(s.number ?? "0", 10) : acc),
                                0
                            )

                            const lastNumber = Math.max(p.sortedSections.length, lastSubsectionNumber)
                            const lastOrder = Math.max(
                                p.sortedSections.length,
                                (last(p.sortedSections)?.order ?? 0) + 1
                            )
                            p.onAddSection({
                                name: i18n("SECTION $1", lastNumber + 1),
                                number: `${lastNumber + 1}`,
                                order: lastOrder
                            })()
                        }}>
                        {i18n("New section title")}
                    </IconButton>
                )}
            </Margin>
            <AttachmentsModal
                visible={!!attachmentModalPositionId}
                isReadonly={p.readonly}
                refId={attachmentModalPositionId}
                onClose={() => setAttachmentPositionId(undefined)}
                {...p.attachments}
            />
            {(isDefined(crbCatalogPosition) &&  (p.CRBAccessAuth.type === "NotFetched") && (
                <NpkCatalogsLogin
                    visible
                    onClose={onModalClose}
                    CRBAccessAuth={p.CRBAccessAuth}
                    auth={p.auth}
                    logon={p.logon}
                    setLicenseDetails={setLicenseDetails}
                    results={p.results}
                   
                />
            )
            )}

            {/* {(isDefined(crbCatalogPosition) && (p.CRBAccessAuth.type === "Fetching")&& (
                <Spinner />
            )
            )} */}

            {(isDefined(crbCatalogPosition) &&  (p.CRBAccessAuth.type === "Fetched") && (
                <NpkCatalogsView
                    visible
                    onClose={onModalClose}
                    CRBAccessAuth={p.CRBAccessAuth}
                    NPKChapters={p.NPKChapters}
                    // NPKChapterVersions={p.NPKChapterVersions}
                    licenseDetails={{...licenseDetails,userId:p.user.userId}}
                    NPKLanguages={p.NPKLanguages}
                    NPKChapterDetails={p.NPKChapterDetails}
                    auth={p.auth}
                    results={p.results}
                    fetchNPKChapters={p.fetchNPKChapters}
                    fetchNPKLanguages={p.fetchNPKLanguages}
                    fetchNPKChapterVersions={p.fetchNPKChapterVersions}
                    fetchNPKChapterDetails={p.fetchNPKChapterDetails}
                />
            )
            )}

            {isDefined(p.devis.shelfVersion ? p.devis.shelfVersion : getDummyCatalogVersion) && isDefined(catalogPosition) && (
                <CatalogPicker
                    shelfType="positions"
                    visible
                    itemsToPreview={toMap(
                        p.sortedSublevels[catalogPosition.sectionId]?.filter(
                            sl => sl.basedOn === "position"
                        ) as Domain.Position[],
                        v => v.positionId,
                        identity
                    )}
                    // eslint-disable-next-line prettier/prettier
                    previewTitle={i18n(
                        "Preview: $1",
                        { ...p.sm.allItems, ...p.subsm.allItems }[catalogPosition.sectionId]?.name || ""
                    )}
                    renderItem={renderCatalogPosition}
                    renderHeader={renderCatalogPositionHeader}
                    onClose={onModalClose}
                    // projectId={projectId}
                    shelfVersion={p.devis.shelfVersion ? p.devis.shelfVersion : getDummyCatalogVersion}
                    item={catalogPosition}
                    getItemId={pos => pos.positionId}
                    mkNewItem={({ sectionId }) =>
                        mkIncompletePosition(sectionId, p.devis.devisId, p.sortedSublevels[sectionId]?.length || 0)
                    }
                    mkCopyItem={pos => ({
                        ...pos,
                        positionId: genTemporaryId(),
                        order: p.sortedSublevels[pos.sectionId]?.length || 0
                    })}
                    attachCatalog={attachCatalogToPosition}
                    mkSchema={() => mkPositionSchema("weak")}
                    mkInitial={identity}
                    mkStyledSchema={mkCatalogPositionStyledSchema}
                    onSubmit={s1 => {
                        p.pm.allItems[s1.positionId] && isOk(runValidatorsRaw([validatePosition], s1))
                            ? p.pm.onItemSubmit(s1 as Domain.Position)({})
                            : p.pm.setUnsavedItem(s1 as Domain.Position)
                    }}
                    onRemove={position => {
                        p.pm.onItemRemove(position as Domain.Position)()
                    }}
                />
            )}
        </>
    )
})
