import { Modal } from "@smartdevis/ui/src/Modal"
import { FlexColumn, FlexItem, FlexRow, GridContainer, HorizontalSpace, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { F0, F1 } from "@smartdevis/utils/src/types"
import React, {useEffect, useState } from "react"
import { Async } from "@smartdevis/utils/src/async"
import { NPKCatalogs } from "@smartdevis/server/src/domain"
import { LicenseUnlockRequestPayload } from "../../../store/crbNpkCatalogsState"
import { Authentication } from "../../../store/authState"
import { SMap } from "@smartdevis/utils/src/map"
import { H5 } from "@smartdevis/ui/src/Typography"
import { Collapsable2 } from "@smartdevis/ui/src/Collapsable"
import { Join } from "@smartdevis/ui/src/utils/Join"
import { useClientWidth } from "../../../hooks/utilityHooks"
import { Spinner } from "@smartdevis/ui/src/Spinner"
import { NPKCatalogChapters, NPKCatalogCollapsableItem } from "./npk-catalogs-chapter"
import { NPKLanguageHandler } from "./npk-catalogs-language"
import { NPKChapterDescription } from "./npk-catalogs-description"
import { NpkCatalogs } from "./npk-catalogs-helper"


export type NpkCatalogsViewProps = {
    visible: boolean
    onClose: F0
    CRBAccessAuth: Async<NPKCatalogs.AccessStatus>
    NPKChapters: Async<NPKCatalogs.Chapters>
    NPKLanguages: Async<NPKCatalogs.Languages>
    NPKChapterDetails:Async<NPKCatalogs.NPKChapter>
    auth: Authentication
    results: SMap<any>
    licenseDetails: LicenseUnlockRequestPayload
    fetchNPKChapters: F1<LicenseUnlockRequestPayload & NPKCatalogs.Language>
    fetchNPKLanguages: F0
    fetchNPKChapterVersions: F1<NPKCatalogs.NPKChapterVersionRequest>
    fetchNPKChapterDetails: F1<NPKCatalogs.NPKChapterVersionRequest & {year: string}>
}

export const NpkCatalogsView = (p:NpkCatalogsViewProps) => {
    const clientWidth = useClientWidth()
    const isScreenNarrow = clientWidth < 1280

    const [selectedLanguage,setLanguage] = useState<NPKCatalogs.Language>({displayLanguage:'Deutsch'})
    const [selectedVersion, setSelectedVersion] = useState<NPKCatalogs.Version>({chapter:'',version:''})
    const [selectedChapter,setChapter] =  useState<NPKCatalogs.NPKChapter | null>(null);
    const [pickerMode,setPickerMode] = useState<{mode: NPKCatalogs.IFields}>({mode:"Position"});
    const [pickerDetails,setPickerDetails] =  useState<any|undefined>(undefined);
    
    React.useEffect(()=>{
        if(p.CRBAccessAuth.type === "Fetched" ){
            if(p.CRBAccessAuth.value.status === 0){
                p.fetchNPKLanguages()
                p.fetchNPKChapters({...p.licenseDetails, displayLanguage:selectedLanguage.displayLanguage})
            }
        }
    },[p.CRBAccessAuth,selectedLanguage])

    useEffect(() => {
            if(p.NPKChapterDetails.type === "Fetched" && !selectedChapter){
                setChapter(p.NPKChapterDetails.value)
            }
    },[p.NPKChapterDetails,selectedChapter])

    const onSelect = (e:any) => {
        const {chapter,year} = e;
        setSelectedVersion({chapter,version:year})
        p.fetchNPKChapterDetails({chapter:chapter,language:selectedLanguage.displayLanguage,year:year});
    }

    if(p.NPKChapters.type === "Fetching") {
        return <>
         <Spinner/>
        </>
    }

    return (
        <Modal
            header={"CRB Catalogs Dashboard"}
            size="xl"
            height="80vh"
            visible={p.visible}
            onClose={p.onClose}
        >
            <GridContainer columnsGrid={isScreenNarrow ? [2, 3] : [2, 2.5, 2]} gap="12px" height="100%">
                <div style={{ height: "10%", position: "fixed" }}>
                    <NPKLanguageHandler currentLanguage={selectedLanguage} results={p.NPKLanguages.type === "Fetched" ? p.NPKLanguages.value : []} setLanguage={(language) => setLanguage({ displayLanguage: language })} />
                </div>
                <div style={{ height: "100%",overflowY: "auto", marginTop: "25px" }}>
                    <NPKCatalogChapters results={p.NPKChapters.type === "Fetched" ? p.NPKChapters.value : []}
                        selectedVersion={selectedVersion}
                        fetchChapterVersions={(chapter: string) => p.fetchNPKChapterVersions({ chapter, language: selectedLanguage.displayLanguage })}
                        onSelect={onSelect}
                    />
                </div>

                <NPKChapterDescription field={pickerMode.mode} chapter={p.NPKChapterDetails} setPickerMode={setPickerMode} setPickerDetails={setPickerDetails}/>
                {/* <NPKCatalogDetailsForm title={pickerMode.mode} chapter={p.NPKChapterDetails} details={pickerDetails} /> */}

            </GridContainer>
        </Modal>
    )
}

type NPKCatalogBrowseChapterProps = {
    title: string
    application?: NPKCatalogs.ArrayOfNPKApplication 
    position?: NPKCatalogs.ArrayOfNPKNode
    setDetails: F1<any>
}

export const NPKCatalogBrowseChapter = (p: NPKCatalogBrowseChapterProps) => {

    const onSelect = (details: any) => { p.setDetails({mode:p.title,details:details})};

    // if (!p.application?.npkapplication || p.application.npkapplication.length === 0) {
    //     return <div>No data available.</div>;
    // }

    if (!p.position?.npknode || p.position?.npknode.length === 0) {
        return <div>No data available.</div>;
    }

    const renderView = () => {
        switch (p.title) {
            case "Chapter":
                return (
                    <div style={{ height: "100%",overflowY: "auto", marginTop: "25px" }}>
                        <FlexColumn>
                             <FlexItem overflow="scroll" direction="column" yAlign="flex-start">
                                    {(p.application?.npkapplication || []).map(division => (
                                        <Collapsable2 title={division.number} sideText={division.description} key={division.number} extra={division} onClick={onSelect}>
                                            <Join items={division.subNodes?.npkapplication || []} renderJoining={() => <VerticalSpace base="8px" />}>
                                                {vwt => (
                                                    <Collapsable2 title={vwt.number} sideText={vwt.description} key={vwt.number} extra={vwt} onClick={onSelect}>
                                                        <Join items={vwt.subNodes?.npkapplication || []} renderJoining={() => <VerticalSpace base="8px" />}>
                                                            {vwt2 => (
                                                                <NPKCatalogCollapsableItem
                                                                    isActive={true}
                                                                    onClick={() => onSelect(vwt2)}
                                                                    key={vwt2.number}>
                                                                    {vwt2.number}-{vwt2.description}
                                                                </NPKCatalogCollapsableItem>
                                                            )}
                                                        </Join>
                                                    </Collapsable2>
                                                )}
                                            </Join>

                                        </Collapsable2>
                                    ))}
                                </FlexItem>
                        </FlexColumn>
                    </div>)
            case "Position":
                return (
                    <div style={{ height: "100%", overflowY: "auto", marginTop: "25px" }}>
                        {/* <FlexColumn>
                            <FlexItem direction="column" overflow="hidden">
                                <VerticalSpace base="16px" />
                                <FlexItem overflow="scroll" direction="column" yAlign="flex-start">
                                    {(p.position?.npknode || []).map(division => (
                                        <Collapsable2 title={division.number} sideText={division.description} key={division.number} extra={division} onClick={onSelect}>
                                            <Join items={division.subNodes?.npknode || []} renderJoining={() => <VerticalSpace base="8px" />}>
                                                {vwt => (
                                                    <Collapsable2 title={vwt.number} sideText={vwt.description} key={vwt.number} extra={vwt} onClick={onSelect}>
                                                        <Join items={vwt.subNodes?.npknode || []} renderJoining={() => <VerticalSpace base="8px" />}>
                                                            {vwt2 => (
                                                                <NPKCatalogCollapsableItem
                                                                    isActive={true}
                                                                    onClick={() => onSelect(vwt2)}
                                                                    key={vwt2.number}>
                                                                    {vwt2.number}-{vwt2.description}
                                                                </NPKCatalogCollapsableItem>
                                                            )}
                                                        </Join>
                                                    </Collapsable2>
                                                )}
                                            </Join>

                                        </Collapsable2>
                                    ))}
                                </FlexItem>
                            </FlexItem>
                        </FlexColumn> */}
                        <NpkCatalogs position={p.position} level={0}/>
                    </div>
                )
        }
    }

    return (<>
        <>
            <FlexRow alignCenter>
                {/* <IconButton icon="ArrowLeft" onClick={p.onBack} /> */}
                <HorizontalSpace base="16px" />
                <H5>{p.title}</H5>
            </FlexRow>
            <VerticalSpace base="16px" />
            <>
                {renderView()}
            </>
        </>
    </>)
}
