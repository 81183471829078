import { NPKCatalogs } from "@smartdevis/server/src/domain"
import { P } from "@smartdevis/ui/src/Typography"
import { FlexColumn, FlexRow, FlexItem, VerticalSpace } from "@smartdevis/ui/src/utils/common"
import { Async } from "@smartdevis/utils/src/async"
import { F1 } from "@smartdevis/utils/src/types"
import { renderResolverLoading, renderResolverError } from "../../../resolvers"
import { i18n } from "../../../services/translations"
import { NPKCatalogBrowseChapter } from "./npk-catalogs"
import { SelectIndicator } from "./npk-catalogs-chapter"
import React from "react"

export type NPKChapterDescriptionProps = {
    field: NPKCatalogs.IFields
    chapter: Async<NPKCatalogs.NPKChapter>
    setPickerMode: F1<{mode: NPKCatalogs.IFields}>
    setPickerDetails: F1<any>
}

export const NPKChapterDescription = (p: NPKChapterDescriptionProps) => {

    switch (p.chapter.type) {
        case "NotFetched":
        case "Fetching":
            return renderResolverLoading({}, p)
        case "FetchError":
            return renderResolverError({}, p.chapter.value, p)
        // case "Fetched":
        //     const { catalogWorkTitle } = data.value
        //     return <CatalogItemForm {...p} workTitle={catalogWorkTitle} />
    }

    return (
        <FlexColumn>
            <FlexRow justifyEnd={false}>
                <FlexItem>
                    <SelectIndicator isActive={p.field === "Chapter"} onClick={() => p.setPickerMode({mode:"Chapter"})} />
                    <P>Chapter</P>
                </FlexItem>
                <FlexItem>
                    <SelectIndicator isActive={p.field === "Position"} onClick={() => p.setPickerMode({mode:"Position"})} />
                    <P>Position</P>
                </FlexItem>
            </FlexRow>
            <VerticalSpace base="16px"/>
            {p.field === "Chapter" && <NPKCatalogBrowseChapter title={i18n("Chapter")} application={p.chapter.value.application} setDetails={p.setPickerDetails}/>}
            {p.field === "Position" && <NPKCatalogBrowseChapter title={i18n("Position")} position={p.chapter.value.subNodes}  setDetails={p.setPickerDetails}/>}
        </FlexColumn>
    )
}
