import { NPKCatalogs } from "@smartdevis/server/src/domain"
import { Asset } from "@smartdevis/ui/src/Asset"
import { DropdownMenuOption, mkDropdownOption, Dropdown } from "@smartdevis/ui/src/Dropdown"
import { P } from "@smartdevis/ui/src/Typography"
import { FlexRow, HorizontalSpace } from "@smartdevis/ui/src/utils/common"
import { F1 } from "@smartdevis/utils/src/types"
import React from "react"

type NPKLanguageHandlerProps = {
    results : NPKCatalogs.Languages
    currentLanguage: NPKCatalogs.Language
    setLanguage: F1<NPKCatalogs.TLanguage>
}
export const NPKLanguageHandler = (p:NPKLanguageHandlerProps) => {
    const options: DropdownMenuOption<NPKCatalogs.TLanguage>[] = []
    p.results.map(languages => {
        options.push(mkDropdownOption(languages, languages))
    })
    return (
        <Dropdown onOptionClick={p.setLanguage} options={options}>
        <FlexRow alignCenter>
            <P small>{p.currentLanguage.displayLanguage.toUpperCase()}</P>
            <HorizontalSpace base="4px" />
            <Asset name="ArrowDown" size="small-icon" color="blueGrey" />
        </FlexRow>
    </Dropdown>
    )

}
