import { clientEnv } from "../env"

export const getProcessEnv = (): clientEnv => ({
    app_id: (process.env as clientEnv).app_id,
    api_key: (process.env as clientEnv).api_key,
    app_url: (process.env as clientEnv).app_url,
    auth_domain: (process.env as clientEnv).auth_domain,
    database_url: (process.env as clientEnv).database_url,
    measurement_id: (process.env as clientEnv).measurement_id,
    messaging_sender_id: (process.env as clientEnv).messaging_sender_id,
    project_id: (process.env as clientEnv).project_id,
    sentry_url: (process.env as clientEnv).sentry_url,
    storage_bucket: (process.env as clientEnv).storage_bucket,
    api_url: (process.env as clientEnv).api_url,
    crm_events_notifications_enabled: (process.env as clientEnv).crm_events_notifications_enabled,
    keycloak_auth_url: (process.env as clientEnv).keycloak_auth_url,
    keycloak_client: (process.env as clientEnv).keycloak_client,
    keycloak_realm: (process.env as clientEnv).keycloak_realm,
    maintenance: (process.env as clientEnv).maintenance,
    google_maps_api_key: (process.env as clientEnv).google_maps_api_key,
    catalog_api_url: (process.env as clientEnv).catalog_api_url,
    feature_show_catalog_positions : (process.env as clientEnv).feature_show_catalog_positions,
    
})

export const isLocal = () => getProcessEnv().project_id === "local"
export const isLocalhost = () => {
    try {
        const loc = window.location.href
        return loc.startsWith("http://localhost:1084")
    } catch (err) {
        return false
    }
}

export const getGoogleMapsAPIUrl = () => {
    const API_KEY = getProcessEnv().google_maps_api_key;
    const API_URL = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`

    return API_URL;
}

export const isDev = () => getProcessEnv().project_id === "dev-smartdevis-380113"
export const isProd = () => getProcessEnv().project_id === "prod-smartdevis"
