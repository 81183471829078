import { StyledFormSchema, useFormHook } from "@smartdevis/forms/src"
import { CrbLicenseDetails } from "@smartdevis/server/src/crbNpkCatalogs"
import { NPKCatalogs } from "@smartdevis/server/src/domain"
import { Button } from "@smartdevis/ui/src/Button"
import { Modal } from "@smartdevis/ui/src/Modal"
import { Spinner } from "@smartdevis/ui/src/Spinner"
import { FlexRow, HorizontalSpace } from "@smartdevis/ui/src/utils/common"
import { Async } from "@smartdevis/utils/src/async"
import { SMap } from "@smartdevis/utils/src/map"
import { F0, F1 } from "@smartdevis/utils/src/types"
import { validString } from "@smartdevis/utils/src/validators"
import React from "react"
import { SubmitButton } from "../../../components"
import { StyledForm } from "../../../components/forms"
import { mkFormSchema, mkTextSchema } from "../../../components/forms/formSchemas"
import { i18n } from "../../../services/translations"
import { Authentication } from "../../../store/authState"
import { LicenseUnlockRequestPayload } from "../../../store/crbNpkCatalogsState"
import styled from "styled-components"
import { themeColor } from "@smartdevis/ui/src/utils/theme"

export type NpkCatalogsLoginProps = {
    visible: boolean
    onClose: F0
    logon:F1<LicenseUnlockRequestPayload>
    CRBAccessAuth: Async<NPKCatalogs.AccessStatus>
    auth: Authentication
    results: SMap<any>
    setLicenseDetails: F1<LicenseUnlockRequestPayload>
}
export const NpkCatalogsLogin = (p:NpkCatalogsLoginProps) => {

    const getStyledLicenseUnlockLoginSchema: F0<StyledFormSchema<Pick<CrbLicenseDetails, "customerID" | "licenseID" | "workStationID">>> = () => [
        { type: "Title", value: i18n("Login to CRB Server") },
        { type: "Row", value: ["customerID"] },
        { type: "Row", value: ["licenseID"] },
        { type: "Row", value: ["workStationID"] }
    ]
    
    const CrbLicenseDetailsValidation = {
        customerID: validString,
        licenseID: validString,
        workStationID: validString
    }
    
    const getSchema = () =>
        mkFormSchema<Pick<CrbLicenseDetails, "customerID" | "licenseID" | "workStationID">>(CrbLicenseDetailsValidation, {
            customerID: mkTextSchema("Customer ID"),
            licenseID: mkTextSchema("License ID"),
            workStationID: mkTextSchema("Workstation ID"),
        })
    

    const onSubmit = () => {
        if(result.type === "Ok" && p.auth.type === "Authenticated") {
            p.setLicenseDetails({...result.value, userId: "100"})
            p.logon({
                ...result.value,
                userId: "100"
            })
        }
    }

    const { formViewProps, handleSubmit, result } = useFormHook({
        schema: getSchema(),
        onSubmit,
        initialValue: { customerID: '1060169', licenseID: 'C4B68E4A1', workStationID: '11' }
    })

    if(p.CRBAccessAuth.type === "Fetching") {
        return <Spinner/>
    }

    return (
    <Modal
        header={"CRB Catalogs login"}
        size="xl"
        height="80vh"
        visible={p.visible}
        onClose={p.onClose}
    >
        <FlexRow justifyCenter>
            <Form onSubmit={handleSubmit}>
                <AdditionalForm>
                    <StyledForm {...formViewProps} styledSchema={getStyledLicenseUnlockLoginSchema()} />
                </AdditionalForm>
                <FlexRow alignCenter justifyEnd>
                    <Button type="button" btnType="action" onClick={p.onClose} data-cy="projects-cancel">
                        {i18n("Cancel")}
                    </Button>

                    <HorizontalSpace base="16px" />
                    <SubmitButton
                        data-cy="projects-submit"
                        // loading={actionState.type === "Processing"}
                        // disabled={actionState.type === "Processing"}
                        type="submit">
                        {i18n('Submit')}
                    </SubmitButton>
                </FlexRow>
            </Form>
        </FlexRow>
    </Modal>
    )
}



const Form = styled.form`
    max-width: 800px;
`
const AdditionalForm = styled.div<{ active?: boolean }>`
    padding: 16px;
    background-color: ${p => (p.active ? themeColor("grey35") : "white")};
    margin-bottom: 16px;
`
