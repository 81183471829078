import * as React from "react"
import dayjs from "dayjs"
import { values } from "@smartdevis/utils/src/map"
import { Domain } from "@smartdevis/server/src/domain"
import { dateFormat } from "@smartdevis/server/src/constants"
import { materializePath } from "../../paths"
import { MutateDevisModal } from "../../components/modals/MutateDevis"
import { mkTextSchema, mkFormSchema } from "../../components/forms/formSchemas"
import { i18n } from "@smartdevis/client/src/services/translations"
import { asyncConnect } from "../../resolvers"
import { mkTile } from "@smartdevis/ui/src/Tiles"
import { mkDropdownOption } from "@smartdevis/ui/src/Dropdown"
import { Asset, AssetStatusWrapper, ImageAsset } from "@smartdevis/ui/src/Asset"
import { Spinner } from "@smartdevis/ui/src/Spinner"
import { TileList } from "@smartdevis/ui/src/TileList"
import { devisValidation } from "@smartdevis/server/src/models/devis"
import { DevisDeleteModal } from "./ProjectsModals"
import { mkLastRound } from "@smartdevis/server/src/models/shared"
import { mkDevis } from "@smartdevis/client/src/utils/devisHelpers"
import { DuplicateDevisModal } from "../../components/modals/DuplicateDevis"
import { prepareMutation } from "@smartdevis/client/src/utils/mutations"
import { isOfferInState } from "@smartdevis/server/src/utils/offer"
import { H6 } from "@smartdevis/ui/src/Typography"

type DevisPaylod = Pick<Domain.Devis, "number">
export const devisSchema = mkFormSchema<DevisPaylod>(devisValidation, { number: mkTextSchema("Section name") })

const isDevisMutable = (requests: Domain.ArchitectOfferRequest[]) =>
    requests.length === 0 || requests.every(r => ["cancelled", "rejected"].includes(r.state.type))

type Status = { name: string; icon: ImageAsset }
export const getDevisStatus = (requests: Domain.ArchitectOfferRequest[], round?: Domain.Round): Status => {
    if (requests.some(o => o.state.type === "contracted")) return { name: i18n("Contract created"), icon: "IconCheck" }

    if (requests.some(o => o.state.type === "negotiation"))
        return { name: i18n("In negotiation"), icon: "IconProgress" }

    if (requests.some(o => o.state.type === "final-proposal"))
        return { name: i18n("Waiting for contractor approval"), icon: "IconProgress" }

    const submitted = requests.filter(r => (round ? r.state.type === "round-submitted" : r.state.type === "submitted"))
    const maxNumber = round ? round.offersIds.length : requests.length

    const whoResponded = requests.some(r => isOfferInState(r, ["round-submitted"]))
        ? i18n("contractors responded to second round")
        : i18n("contractors responded")
    if (submitted.length > 0 && submitted.length === maxNumber)
        return { icon: "IconReceived", name: i18n("All") + " " + whoResponded }

    if (submitted.length > 0)
        return {
            name: `${submitted.length} ` + i18n("of") + ` ${maxNumber} ` + whoResponded,
            icon: "IconProgress"
        }

    if (requests.length > 0 && requests.every(r => r.state.type === "rejected"))
        return { name: i18n("All offers rejected"), icon: "IconRejected" }

    if (requests.some(r => isOfferInState(r, ["next-round"])))
        return { name: i18n("Next round requested"), icon: "IconProgress" }

    if (requests.some(r => isOfferInState(r, ["requested", "adopted"])))
        return { name: i18n("Offer requested"), icon: "IconProgress" }

    return round
        ? { name: i18n("In second round"), icon: "IconProgress" }
        : { name: i18n("Created"), icon: "IconProgress" }
}

export const StatusComponent: React.FC<{ status: Status }> = p => (
    <AssetStatusWrapper maxWidth={240} listMode>
        <Asset name={p.status.icon} size="icon" />
        <H6 color="grey70">{p.status.name}</H6>
    </AssetStatusWrapper>
)

export const DevisListView = asyncConnect({
    stateResolvers: ["projectDetails", "latestConditionsShelf","latestPositionsShelf", "allDevis", "projectDevisCollections"],
    actions: ["mutate", "navigate"],
    renderLoading: () => <Spinner />
})(p => {
    const { projectId } = p.projectDetails

    const [showCreate, setShowCreate] = React.useState(false)
    const [editedId, setEditedId] = React.useState<string | null>(null)
    const [devisToDuplicate, setDevisToDuplicate] = React.useState<null | Domain.Devis>(null)
    const [devisToDelete, setDevisToDelete] = React.useState<null | Domain.Devis>(null)
    const devisTiles = mkDevis(p).map(d => {
        const requests = values(p.projectDevisCollections[d.devisId].requests)
        const startDate = d.workStartTs ? dayjs(d.workStartTs).format(dateFormat) : i18n("Not specified")
        const round = mkLastRound(p.projectDevisCollections[d.devisId])
        const status = getDevisStatus(
            requests.filter(r => r.devisId === d.devisId),
            round
        )

        return mkTile(d.devisId, d.workCategory, {
            description: i18n("Planned start: $1", startDate),
            status: { type: "component", value: <StatusComponent status={status} /> },
            linkTo: materializePath("submitters", { projectId: d.projectId, devisId: d.devisId }),
            options: [
                mkDropdownOption(i18n("Edit"), "edit", () => setEditedId(d.devisId)),
                mkDropdownOption(i18n("Remove"), "remove", () => setDevisToDelete(d)),
                mkDropdownOption(i18n("Duplicate"), "duplicate", () => setDevisToDuplicate(d))
            ]
        })
    })

    const mutateDevisModalVisible = showCreate || Boolean(editedId)

    return (
        <>
            <TileList
                items={devisTiles}
                createProps={{
                    title: i18n("New tender"),
                    withDropdown: false,
                    onClick: () => setShowCreate(true)
                }}
            />
            {mutateDevisModalVisible && (
                <MutateDevisModal
                    visible
                    isMutable={editedId ? isDevisMutable(values(p.projectDevisCollections[editedId].requests)) : true}
                    onClose={() => {
                        setShowCreate(false)
                        setEditedId(null)
                    }}
                    editState={
                        // editedId
                        //     ? { type: "update", value: p.allDevis[editedId] }
                        //     : {
                        //           type: "create",
                        //           value: { projectId }
                        //       }
                        editedId
                        ? { type: "update", value: p.allDevis[editedId] }
                        : {
                              type: "create",
                              value: {
                                  projectId,
                                //   shelfVersion: p.latestPositionsShelf.meta.version,
                                //   shelfConditionsVersion:
                                //       p.projectDetails.shelfConditionsVersion ||
                                //       p.latestConditionsShelf.meta.version
                              }
                          }
                    }
                    modalTitle={editedId ? i18n("Update Tender") : i18n("Create Tender")}
                    saveButtonTitle={i18n("Save")}
                />
            )}
            {devisToDelete && (
                <DevisDeleteModal
                    show={!!devisToDelete}
                    onConfirm={prepareMutation("devis", p.mutate, {
                        type: "devis",
                        projectId,
                        devisId: devisToDelete.devisId
                    }).remove(devisToDelete)}
                    setShow={_ => setDevisToDelete(null)}
                    isDeletable={isDevisMutable(values(p.projectDevisCollections[devisToDelete.devisId].requests))}
                />
            )}
            {devisToDuplicate && (
                <DuplicateDevisModal baseDevis={devisToDuplicate} visible onClose={() => setDevisToDuplicate(null)} />
            )}
        </>
    )
})
