import { NPKCatalogs } from "@smartdevis/server/src/domain"
import { IconButton } from "@smartdevis/ui/src/Button"
import { Collapsable2 } from "@smartdevis/ui/src/Collapsable"
import { Empty } from "@smartdevis/ui/src/Empty"
import { H4, P } from "@smartdevis/ui/src/Typography"
import { Join } from "@smartdevis/ui/src/utils/Join"
import { FlexColumn, FlexRow, HorizontalSpace, VerticalSpace, styleFromProp, styleIfProp } from "@smartdevis/ui/src/utils/common"
import { F0, F1 } from "@smartdevis/utils/src/types"
import { values } from "lodash"
import { i18n } from "../../../services/translations"
import React from "react"
import styled from "styled-components"
import { themeColor } from "@smartdevis/ui/src/utils/theme"

type NPKCatalogChaptersProps = {
    results : NPKCatalogs.Chapters
    selectedVersion: NPKCatalogs.Version
    fetchChapterVersions : F1<string>
    onSelect: F1<Omit<NPKCatalogs.NPKChapterVersionRequest & {year: string},'language'>>
}
export const NPKCatalogChapters =  (p:NPKCatalogChaptersProps) => {

    const results = values(p.results);

    if(!results.length)
        return (
        <Empty type="node"
            value ={
                <FlexColumn alignCenter>
                        {<H4>{i18n("NPK Catalogs")}</H4>}
                        <P>
                            {i18n("Search for construction work descriptions and add them easily to the devi's.")}
                        </P>
                        <VerticalSpace base="16px" />
                        <IconButton
                            icon="Search"
                            // onClick={p.onBrowseShelf}
                            data-cy="positions-search-catalog-browse-all">
                            {i18n("Browse all catalogs")}
                        </IconButton>
                    </FlexColumn>
            }
        />
        )

    return (
        <FlexColumn>
          {p.results.map(v => (
             <Collapsable2 title={v.display} sideText={values(v.number).length} key={v.number} extra={v.number} onClick={p.fetchChapterVersions}>
             <Join items={v.versions ? v.versions : []} key={v.number} renderJoining={() => <VerticalSpace base="8px" />}>
                 {vp => (
                     <NPKCatalogCollapsableItem
                         isActive={p.selectedVersion.chapter === v.number && p.selectedVersion.version === vp.version.toString() }
                         onClick={() => p.onSelect({chapter: v.number, year: vp.version.toString()})}
                         key={vp.releaseYear}>
                         {`${vp.version}(${vp.releaseYear})`}
                     </NPKCatalogCollapsableItem>
                 )}
             </Join>
             <VerticalSpace base="8px" />
             {/* <Button
                 btnType="link"
                 onClick={() => p.onBrowseCatalog(v.catalogId)}
                 data-cy="positions-search-catalog-browse-all">
                 {i18n("Browse catalog")}
             </Button> */}
         </Collapsable2>
          ))}
        </FlexColumn>
    )

}


export const SelectIndicator = styled.span<{ isActive?: boolean }>`
    box-sizing: border-box;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    padding: ${styleIfProp("isActive", "3px", "6px")};

    transition: border 0.2s ease-in, padding 0.2s ease-in;

    border: ${styleIfProp("isActive", "5px", "2px")} solid ${p => themeColor(p.isActive ? "primary" : "borderGrey")(p)};
`

export const CollapsableRow = styled(FlexRow)<{ isActive?: boolean }>`
    cursor: pointer;

    &:hover ${SelectIndicator} {
        ${p => styleFromProp(!p.isActive, `border: 2px solid ${themeColor("primary")(p)}`)};
    }
`

export const NPKCatalogCollapsableItem: React.FC<{ isActive?: boolean; onClick?: F0 }> = p => (
    <CollapsableRow noShrink onClick={p.onClick} alignCenter isActive={p.isActive}>
        <SelectIndicator isActive={p.isActive} />
        <HorizontalSpace base="8px" />
        {p.children}
    </CollapsableRow>
)