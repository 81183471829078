import * as React from "react"
import { projectCollectionsKeys } from "@smartdevis/server/src/constants"
import { Domain } from "@smartdevis/server/src/domain"
import { AsyncConnectResults } from "../resolvers"
import { mkLastRound, mkDeltasByRef } from "@smartdevis/server/src/models/shared"
import { useItemsMap } from "../hooks/utilityHooks"
import { RowVisual } from "../components/table/TableRow.styles"
import { isOfferInState } from "@smartdevis/server/src/utils/offer"
import { Row } from "../components/table/Table"
import { sortCreatable } from "@smartdevis/utils/src/comparable"
import { values, SMap } from "@smartdevis/utils/src/map"
import { isEqual, isDefined } from "@smartdevis/utils/src/misc"
import { F2, F1, F3, F0 } from "@smartdevis/utils/src/types"
import { isEmpty } from "@smartdevis/utils/src/validators"
import { genTemporaryId } from "@smartdevis/utils/src/id"
import { prepareMutation } from "./mutations"
import { i18n } from "../services/translations"
import { useUnsavedStatusAsChild } from "../views/UnsavedStatusProvider"

export const roundDecimalNumber = (n: number): number => Math.round(n * 10000) / 10000

export const hasRequests = (p: Pick<Domain.Project, "requests">, devisId: string) =>
    values(p.requests).some(r => r.devisId === devisId && !isOfferInState(r, ["cancelled", "rejected"]))

export const hasRoundRequests = (p: Pick<Domain.Project, "requests">, devisId: string) =>
    values(p.requests).some(r => r.devisId === devisId && isOfferInState(r, ["next-round", "round-submitted"]))

export const hasContract = (p: Pick<Domain.Project, "requests">, devisId: string) =>
    values(p.requests).some(r => r.devisId === devisId && r.state.type === "contracted")

export const isFinalizing = (p: Pick<Domain.Project, "requests">, devisId: string) =>
    values(p.requests).some(r => r.devisId === devisId && r.state.type === "final-proposal")

export const hasNegotiations = (p: Pick<Domain.Project, "requests">, devisId: string) =>
    values(p.requests).some(r => r.devisId === devisId && r.statesHistory.find(h => h.state.type === "negotiation"))

export const isInNegotiations = (p: Pick<Domain.Project, "requests">, devisId: string) =>
    values(p.requests).some(r => r.devisId === devisId && r.state.type === "negotiation")

export const hasSecondRound = (p: Pick<Domain.Project, "rounds">, devisId: string) =>
    values(p.rounds).some(r => r.devisId === devisId)

type AcceptableCollections = keyof Pick<
    Domain.ProjectCollections,
    "conditions" | "deductions" | "positions" | "sections" | "additionalInformation"
>

export type MutationManagement<C extends AcceptableCollections, T extends Domain.ProjectCollectionItem<C>> = {
    onItemSubmit: (item: T, actionId?: string) => (delta: Partial<T>) => void
    onItemRemove: (item: T, actionId?: string) => () => void
    onRevertDelta?: (deltaId?: string) => F0 | undefined
    allItems: SMap<T>
    setUnsavedItem: (item: T) => void
    isUnsaved: (id: string) => boolean
    isUnsavedMapEmpty: boolean
}

export const useMutationManagement = <C extends AcceptableCollections, T extends Domain.ProjectCollectionItem<C>>(
    collection: C,
    initialMap: SMap<T>,
    p: AsyncConnectResults<
        "projectDetails" | "devis" | "devisCollections" | "isDevisReadonly",
        "mutate" | "submitRoundDelta" | "revertRoundDelta"
    >
): MutationManagement<C, T> => {
    const { devisId } = p.devis
    const { projectId } = p.projectDetails

    const idKey = projectCollectionsKeys[collection] as keyof T
    const getId = (t: T) => t[idKey] as unknown as string
    const { items: unsaved, setItemByKey: setUnsavedItem, removeItem: removeUnsaved } = useItemsMap<T>({}, idKey)
    const allItems: SMap<T> = { ...initialMap, ...unsaved }

    const round = mkLastRound(p.devisCollections)
    const deltas = mkDeltasByRef(p.devisCollections)

    const { update, remove, create } = prepareMutation<C, T>(collection, p.mutate, {
        type: "devis",
        projectId,
        devisId
    })

    const onItemRemove =
        (item: T, actionId = genTemporaryId()) =>
        () => {
            if (unsaved[getId(item)]) return removeUnsaved(getId(item))
            if (round)
                return p.submitRoundDelta({
                    actionId,
                    projectId,
                    devisId,
                    delta: {
                        type: "remove",
                        deltaId: genTemporaryId(),
                        parent: collection,
                        refId: getId(item),
                        roundId: round.roundId
                    }
                })
            remove(item, actionId)()
        }

    const onRevertDelta = (deltaId?: string) =>
        round && deltaId && !p.isDevisReadonly
            ? () =>
                  p.revertRoundDelta({
                      roundId: round.roundId,
                      projectId,
                      devisId,
                      actionId: genTemporaryId(),
                      deltaId
                  })
            : undefined

    const onItemSubmit =
        (item: T, actionId = genTemporaryId()) =>
        (delta: Partial<T>) => {
            const payload: T = { ...item, ...delta }
            if (round) {
                if (isEqual(payload, allItems[getId(item)])) return
                if (isEqual(payload, p.devisCollections[collection][getId(item)]))
                    return onRevertDelta(deltas?.[getId(item)]?.deltaId)?.()
                const isNew = unsaved[getId(item)] || deltas?.[getId(item)]?.type === "add" || !initialMap[getId(item)]
                p.submitRoundDelta({
                    actionId: genTemporaryId(),
                    projectId,
                    devisId,
                    delta: {
                        type: isNew ? "add" : "edit",
                        deltaId: deltas?.[getId(item)]?.deltaId ?? genTemporaryId(),
                        parent: collection,
                        refId: getId(item),
                        roundId: round.roundId,
                        value: payload
                    } as Domain.RoundDelta
                })
            } else {
                unsaved[getId(item)] ? create(payload, actionId) : update(item, actionId)(delta)
            }
            removeUnsaved(getId(item))
        }

    const { setUnsaved } = useUnsavedStatusAsChild(collection)
    React.useEffect(() => {
        setUnsaved(!isEmpty(unsaved))
    }, [isEmpty(unsaved)])

    return {
        onItemSubmit,
        onItemRemove,
        onRevertDelta,
        allItems,
        setUnsavedItem,
        isUnsaved: (id: string) => isDefined(unsaved[id]),
        isUnsavedMapEmpty: isEmpty(unsaved)
    }
}

export const useProjectLevelMutationManagement = <
    C extends Exclude<AcceptableCollections, "positions">,
    T extends Domain.ProjectCollectionItem<C>
>(
    collection: C,
    initialMap: SMap<T>,
    p: AsyncConnectResults<"projectDetails" | "projectPredefinedCollections", "mutate">
): MutationManagement<C, T> => {
    const { projectId } = p.projectDetails

    const idKey = projectCollectionsKeys[collection] as keyof T
    const getId = (t: T) => t[idKey] as unknown as string
    const { items: unsaved, setItemByKey: setUnsavedItem, removeItem: removeUnsaved } = useItemsMap<T>({}, idKey)
    const allItems: SMap<T> = { ...initialMap, ...unsaved }

    const { update, remove, create } = prepareMutation<C, T>(collection, p.mutate, {
        type: "project",
        projectId
    })

    const onItemRemove =
        (item: T, actionId = genTemporaryId()) =>
        () =>
            unsaved[getId(item)] ? removeUnsaved(getId(item)) : remove(item, actionId)()

    const onItemSubmit =
        (item: T, actionId = genTemporaryId()) =>
        (delta: Partial<T>) => {
            const payload: T = { ...item, ...delta }
            unsaved[getId(item)] ? create(payload, actionId) : update(item, actionId)(delta)
            removeUnsaved(getId(item))
        }

    const isUnsaved = (id: string) => isDefined(unsaved[id])
    const isUnsavedMapEmpty = isEmpty(unsaved)

    const { setUnsaved } = useUnsavedStatusAsChild(`ProjectLevel${collection}`)
    React.useEffect(() => {
        setUnsaved(!isUnsavedMapEmpty)
    }, [isUnsavedMapEmpty])

    return { onItemSubmit, onItemRemove, allItems, setUnsavedItem, isUnsaved, isUnsavedMapEmpty }
}

export const getRowVisualFromDelta = (d?: Domain.RoundDelta): RowVisual[] => {
    if (!d) return []
    switch (d.type) {
        case "add":
            return ["parent", "added"]
        case "edit":
            return ["parent", "edited"]
        case "remove":
            return ["parent", "removed"]
    }
}

export const mkDevis = (p: AsyncConnectResults<"projectDetails" | "allDevis" | "latestConditionsShelf" | "latestPositionsShelf">) =>
    values(p.allDevis)
        .filter(d => d.projectId === p.projectDetails.projectId)
        .sort(sortCreatable())
        .filter(
            d =>
                (d.shelfConditionsVersion ?? 0) <= p.latestConditionsShelf?.meta?.version &&
                (d.shelfVersion ?? 0) <= p.latestPositionsShelf?.meta?.version
        )

export const getSublevelIndex = (sls: Domain.Sublevel[] = [], id: string) =>
    sls.findIndex(sl => (sl.basedOn === "position" ? sl.positionId === id : sl.sectionId === id))
const mkSublevelRow = (
    root: Domain.Sublevel,
    sublevelsBySection: SMap<Domain.Sublevel[]>,
    mkSectionRow: F2<Domain.SectionSublevel, Row[], Row<"section" | "sublevel">>,
    mkPositionRow: F1<Domain.PositionSublevel, Row<"sublevel">>
): Row<"section" | "sublevel"> => {
    if (root.basedOn === "position") return mkPositionRow(root)
    const sublevels = sublevelsBySection[root.sectionId] || []
    return mkSectionRow(
        root,
        sublevels.map(sl => mkSublevelRow(sl, sublevelsBySection, mkSectionRow, mkPositionRow))
    )
}
type SectionSublevelType = "section" | "sublevel"
export const mkDevisPositionsRows = (
    sections: Domain.Section[],
    sublevelsBySection: SMap<Domain.Sublevel[]>,
    mkSectionRow: F3<SectionSublevelType, Domain.SectionSublevel, Row[], Row<SectionSublevelType>>,
    mkPositionRow: F1<Domain.PositionSublevel, Row<"sublevel">>
): Row<SectionSublevelType>[] => {
    return sections.map(s => {
        const sublevels = sublevelsBySection[s.sectionId] || []
        const subrows = sublevels.map(sl =>
            mkSublevelRow(sl, sublevelsBySection, (ssl, r) => mkSectionRow("sublevel", ssl, r), mkPositionRow)
        )
        return mkSectionRow("section", s as Domain.SectionSublevel, subrows)
    })
}

export const mkFlatDevisPositionsRows = <T extends any>(
    sections: Domain.Section[],
    sublevelsBySection: SMap<Domain.Sublevel[]>,
    mkSectionRow: F1<Domain.Section, T>,
    mkSubsectionRow: F1<Domain.SectionSublevel, T>,
    mkPositionRow: F1<Domain.PositionSublevel, T>
): T[] => {
    const mkRow = (sl: Domain.Sublevel) => (sl.basedOn === "position" ? mkPositionRow(sl) : mkSubsectionRow(sl))
    return sections
        .map(s => {
            const sublevels = sublevelsBySection[s.sectionId] || []
            const subsections: T[] = sublevels
                .map(sl => {
                    if (sl.basedOn === "position") return [mkRow(sl)]
                    else return [mkRow(sl), ...(sublevelsBySection[sl.sectionId] || []).map(mkRow)]
                })
                .flat()
            return [mkSectionRow(s), ...subsections]
        })
        .flat()
}

export const getStepExitWarningText = () => i18n("You have unsaved items. If you leave, the data will be lost")
