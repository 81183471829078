import { LicenseUnlockInfo, LicenceInfo, LicenceIDs, SubscribedWorkStations, } from "@smartdevis/server/src/crbNpkCatalogs"
import { mkReducer } from "./utils"
import { Authentication } from "./authState"
import { createAction } from "@smartdevis/utils/src/actions"
import { Async, mkFetchError, mkFetched, mkFetching, mkNotFetched } from "@smartdevis/utils/src/async"
import { mkFetchCmd } from "./dataQueries"
import { Cmd } from "redux-loop"
import { DataState, clientActions } from "."
import axios from "axios"
import { NPKCatalogs } from "@smartdevis/server/src/domain"
import { CrbServerLogon, fetchNPKChapterDetails, fetchNPKChapterVersions, fetchNPKChapters, fetchNPKLanguages } from "./dataQueries/licenseDirectory"
import { SMap } from "@smartdevis/utils/src/map"

const url = '';

export type CrbNpkCatalogsState = {
    CRBAccessAuth: Async<NPKCatalogs.AccessStatus>
    NPKChapters: Async<NPKCatalogs.Chapters>
    NPKLanguages: Async<NPKCatalogs.Languages>
    NPKChapterVersions : SMap<Async<NPKCatalogs.NPKChapterVersion[]>>
    NPKChapterDetails : Async<NPKCatalogs.NPKChapter>

    LicenseUnlockInfo: Async<LicenseUnlockInfo>
    LicenceInfo: Async<LicenceInfo>
    LicenceIDs: Async<LicenceIDs>
    SubscribedWorkStations: Async<SubscribedWorkStations>
}

export const initialState: CrbNpkCatalogsState = {
    CRBAccessAuth : mkNotFetched(),
    NPKChapters : mkNotFetched(),
    NPKLanguages: mkNotFetched(),
    NPKChapterVersions: {},
    NPKChapterDetails:mkNotFetched(),

    LicenseUnlockInfo: mkNotFetched(),
    LicenceInfo: mkNotFetched(),
    LicenceIDs: mkNotFetched(),
    SubscribedWorkStations: mkNotFetched()
}

export const actions = {
    _setCRBAccessAuth: (p: Async<NPKCatalogs.AccessStatus>) => createAction("_setCRBAccessAuth", p),
    _setNPKLanguages: (p: Async<NPKCatalogs.Languages>) => createAction("_setNPKLanguages",p),

    _setNPKChapters: (p: Async<NPKCatalogs.Chapters>) => createAction("_setNPKChapters", p),
    _setNPKChapterVersions: (chapter: string, versions: Async<NPKCatalogs.NPKChapterVersion[]>) => createAction("_setNPKChapterVersions", { chapter, versions }),
    // _setNPKChapterDetails: (chapter: string, version: string, collection: Async<NPKCatalogs.NPKChapter>) => createAction("_setNPKChapterDetails", { chapter, version, collection }),
    _setNPKChapterDetails: (collection: Async<NPKCatalogs.NPKChapter>) => createAction("_setNPKChapterDetails", collection),
    
    
    logon: (payload:LicenseUnlockRequestPayload) => createAction("logon",payload),
    fetchNPKLanguages: () => createAction("fetchNPKLanguages"),

    fetchNPKChapters: (p:LicenseUnlockRequestPayload & NPKCatalogs.Language) => createAction("fetchNPKChapters",p),
    fetchNPKChapterVersions: (p: NPKCatalogs.NPKChapterVersionRequest) => createAction("fetchNPKChapterVersions",p),
    fetchNPKChapterDetails: (p:NPKCatalogs.NPKChapterVersionRequest & {year: string}) => createAction("fetchNPKChapterDetails",p),

    _flushState: () => createAction("_flushState"),
    _setCatalogsRes: <T extends keyof CrbNpkCatalogsState>(field: T, v: CrbNpkCatalogsState[T]) => createAction("_setCatalogsRes", { [field]: v }),
    _setLicenseUnlockInfo: (licenseUnlockInfo: Async<LicenseUnlockInfo>) => createAction("_setLicenseUnlockInfo", { licenseUnlockInfo }),
    _setLicenseInfo: (licenseInfo: Async<LicenceInfo>) => createAction("_setLicenseInfo", { licenseInfo }),
    _setLicenseIds: (licenseIds: Async<LicenceIDs>) => createAction("_setLicenseIds", { licenseIds }),
    _setSubscribedWorkStattions: (workStationIds: Async<SubscribedWorkStations>) => createAction("_setSubscribedWorkStattions", { workStationIds }),
    fetchLicenseUnlockInfo: (p: any) => createAction("fetchLicenseUnlockInfo", p),
    fetchLicenseInfo: (p: any) => createAction("fetchLicenseInfo", p),
    fetchLicenseIds: (p: any) => createAction("fetchLicenseIds", p),
    fetchSubscribedWorkstations: (p: any) => createAction("fetchSubscribedWorkstations", p)

}

const extendSubstate = <K extends keyof CrbNpkCatalogsState>(
    state: CrbNpkCatalogsState,
    key: K,
    delta: Partial<CrbNpkCatalogsState[K]>
): Partial<DataState> => ({
    [key]: { ...state[key], ...delta }
})

const addChapterVersions = (
    state: CrbNpkCatalogsState,
    chapterNo: string,
    delta: Async<NPKCatalogs.NPKChapterVersion[]>
): Partial<CrbNpkCatalogsState> => {
    let currentChaptersState = state["NPKChapters"];
    if (currentChaptersState.type === "Fetched") {
        currentChaptersState.value.forEach((chapter: NPKCatalogs.Chapter) => {
            if (chapter.number === chapterNo) {
                chapter.versions = delta.type === "Fetched" ? delta.value : []
            }
        })
    }
    return { ...state, ...currentChaptersState }
}

const addChapterDetails = (
    state: CrbNpkCatalogsState,
    number: string,
    version: string,
    delta: Async<NPKCatalogs.NPKChapter>
): Partial<CrbNpkCatalogsState> => {
    let currentChaptersState = state["NPKChapters"];
    if (currentChaptersState.type === "Fetched") {
        currentChaptersState.value.forEach((chapter: NPKCatalogs.Chapter) => {
            if (chapter.number === number) {
                // chapter.versions = delta.type === "Fetched" ? delta.value : []
                chapter.versions?.forEach((v: NPKCatalogs.NPKChapterVersion) => {
                    if (v.version.toString() === version) {
                        v.chapter = delta.type === "Fetched" ? delta.value : undefined
                    }
                })
            }
        })
    }
    return { ...state, ...currentChaptersState }
}



export const reducer = mkReducer<CrbNpkCatalogsState>((state, action, ctx) => {
    switch (action.type) {

        case "_setCRBAccessAuth":
            return { CRBAccessAuth : action.payload }
        case "_setNPKLanguages":
            return { NPKLanguages : action.payload }
        case "_setNPKChapters":
            return { NPKChapters : action.payload }
        // case "_setNPKChapterVersions": 
        //     return extendSubstate(state, "NPKChapterVersions", { [action.payload.chapter]: action.payload.versions })

        case "_setNPKChapterVersions":
            return addChapterVersions(state,action.payload.chapter,action.payload.versions)

        case "_setNPKChapterDetails":
            return { NPKChapterDetails: action.payload }
            // const {chapter,version,collection} = action.payload
            // return addChapterDetails(state,chapter,version,collection)
    
        // case "_setNPKChapterDetails": 
        //     return extendSubstate(state, "NPKChapterDetails", { [action.payload.chapter]: { [action.payload.year]:action.payload.details } })


        case "logon":
            return CrbServerLogon(ctx.auth,action.payload)
        case "fetchNPKLanguages":
            return fetchNPKLanguages(ctx.auth)

        case "fetchNPKChapters":
            return fetchNPKChapters(ctx.auth,action.payload)
        case "fetchNPKChapterVersions":
            return fetchNPKChapterVersions(ctx.auth,action.payload)
        case "fetchNPKChapterDetails":
            return fetchNPKChapterDetails(ctx.auth,action.payload)



        case "_setCatalogsRes":
            return action.payload
        case "_flushState":
            return initialState
        case "_setLicenseUnlockInfo":
            return { ...state, LicenseUnlockInfo: action.payload.licenseUnlockInfo }
        case "_setLicenseInfo":
            return { ...state, getLicenceInfo: action.payload.licenseInfo }
        case "_setLicenseIds":
            return { ...state, getLicenceIDs: action.payload.licenseIds }
        case "_setSubscribedWorkStattions":
            return { ...state, getSubscribedWorkStations: action.payload.workStationIds }
        // case "fetchLicenseUnlockInfo":
        //     return fetchLicenseUnlockInfo(ctx.auth, action.payload)
        case "fetchLicenseInfo":
            return fetchLicenseInfo(ctx.auth, action.payload);
        case "fetchLicenseIds":
            return fetchLicenseIds(ctx.auth, action.payload);
        case "fetchSubscribedWorkstations":
            return fetchSubscribedWorkstations(ctx.auth, action.payload);
    }
})

export type LicenseUnlockRequestPayload = {
    customerID: string,
    licenseID : string,
    workStationID: string,
    userId : string
}


export const fetchLicenseInfo = (auth: Authentication, payload: any,) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setCatalogsRes("LicenceInfo", mkFetching())),
            core: Cmd.run(
                async () =>
                    axios.post(`${url}/getLicenceInfo`, payload, {
                        headers: { 'Authorization': `Bearer ${auth.type === "Authenticated" ? auth.value.token : ""}` }
                    }).then((res) => {
                        return res.data;
                    })
                ,
                {
                    successActionCreator: res => clientActions._setLicenseInfo(mkFetched(res)),
                    failActionCreator: (error: Error) =>
                        clientActions._setCatalogsRes("LicenceInfo", mkFetchError(error.message))
                }
            )
        },
    )
export const fetchLicenseIds = (auth: Authentication, payload: any,) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setCatalogsRes("LicenceIDs", mkFetching())),
            core: Cmd.run(
                async () =>
                    axios.post(`${url}/getLicenceIDs`, payload, {
                        headers: { 'Authorization': `Bearer ${auth.type === "Authenticated" ? auth.value.token : ""}` }
                    }).then((res) => {
                        return res.data;
                    })
                ,
                {
                    successActionCreator: res => clientActions._setLicenseIds(mkFetched(res)),
                    failActionCreator: (error: Error) =>
                        clientActions._setCatalogsRes("LicenceIDs", mkFetchError(error.message))
                }
            )
        },
        payload
    )
export const fetchSubscribedWorkstations = (auth: Authentication, payload: any,) =>
    mkFetchCmd(
        {
            pre: Cmd.action(clientActions._setCatalogsRes("SubscribedWorkStations", mkFetching())),
            core: Cmd.run(
                async () =>
                    axios.post(`${url}/getSubscribedWorkStations`, payload, {
                        headers: { 'Authorization': `Bearer ${auth.type === "Authenticated" ? auth.value.token : ""}` }
                    }).then((res) => {
                        return res.data;
                    })
                ,
                {
                    successActionCreator: res => clientActions._setSubscribedWorkStattions(mkFetched(res)),
                    failActionCreator: (error: Error) =>
                        clientActions._setCatalogsRes("SubscribedWorkStations", mkFetchError(error.message))
                }
            )
        },
        payload
    )


export const loginToCrbServer = (auth: Authentication, payload: any,) =>
        mkFetchCmd(
            {
                pre: Cmd.action(clientActions._setCatalogsRes("SubscribedWorkStations", mkFetching())),
                core: Cmd.run(
                    async () =>
                        axios.post(`${url}/getSubscribedWorkStations`, payload, {
                            headers: { 'Authorization': `Bearer ${auth.type === "Authenticated" ? auth.value.token : ""}` }
                        }).then((res) => {
                            return res.data;
                        })
                    ,
                    {
                        successActionCreator: res => clientActions._setSubscribedWorkStattions(mkFetched(res)),
                        failActionCreator: (error: Error) =>
                            clientActions._setCatalogsRes("SubscribedWorkStations", mkFetchError(error.message))
                    }
                )
            },
            payload
        )
